import React from 'react';
import classes from './styles.module.scss';

const ScreenDescription = () => {
    return (
        <p className={classes.text}>
            QIE Blockchain stands as a cornerstone for Web 3 and DeFi, offering a robust, low-cost network featuring rapid settlement for transaction efficiency.
        </p>
    );
};

export default ScreenDescription;