import React from 'react';
import classes from './styles.module.scss';

const CopyrightBlock = () => {
    return (
        <p className={classes.text}>
            © {new Date().getFullYear()} QIE Blockchain. All Rights Reserved
        </p>
    );
};

export default CopyrightBlock;