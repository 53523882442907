import QidexIcon from 'assets/images/qidex-icon.png';
import QiStablecoinIcon from 'assets/images/qi-stablecoin-icon.png';
import HovrIcon from 'assets/images/hovr-icon.png';
import PowsomeIcon from 'assets/images/pawsome-icon.png';
import TokenCreatorIcon from 'assets/images/token-creator-icon.png';
import DocIcon from 'assets/images/doc-icon.png';
import HovrHooligans from 'assets/images/hovr-hooligans.png';
import DoodleIcon from 'assets/images/doodle-icon.png';
import GalaxyIcon from 'assets/images/galaxy-icon.png';
import NexBlocIcon from 'assets/images/nexbloc-icon.png';

const config = [
    [
        {
            id:11,
            sectionName: 'Token swaps',
            sectionCards: [
                {
                    id: 110,
                    icon: QidexIcon,
                    heading: 'QIE DEX',
                    text: 'Decentralised exchange on QIE blockchain with an automated liquidity provision protocol for all tokens listed.',
                    new: 'New',
                    symbol: 'QIDEX',
                    appLink: 'https://qidex.site/',
                    tag: '',
                    symbolLink: 'https://mainnet.qiblockchain.online/token/0x0f2380e602dd7ea5d717d8bf78c52496f1d54a96'
                }
            ]
        },
        {
            id:12,
            sectionName: 'Payments',
            sectionCards: [
                {
                    id: 120,
                    icon: QiStablecoinIcon,
                    heading: 'QI USD',
                    text: 'Over-Collateralized Decentralized Stablecoin.',
                    new: 'New',
                    symbol: 'QiUSD',
                    appLink: 'https://qiusd.online/#/',
                    tag: '',
                }
            ]
        },
        {
            id:13,
            sectionName: 'Staking',
            sectionCards: [
                {
                    id: 130,
                    icon: TokenCreatorIcon,
                    heading: 'QIE Staking',
                    text: 'Stake QIE and earn QIDEX tokens with high APY.',
                    new: '',
                    symbol: '',
                    appLink: 'https://qidex.site/#/stake',
                    tag: 'Recommendation'
                }
            ]
        },
        {
            id:14,
            sectionName: 'Trading',
            sectionCards: [
                {
                    id: 140,
                    icon: GalaxyIcon,
                    heading: 'Galaxi Invest',
                    text: 'AI based autotrading global stocks.',
                    new: 'New',
                    symbol: '',
                    appLink: 'https://play.google.com/store/apps/details?id=com.galaxi.android&gl=US',
                    tag: '',
                    soon: ''
                }
            ]
        },
    ],
    [
        {
            id:4,
            sectionName: 'NFT',
            sectionCards: [
                {
                    id: 40,
                    icon: HovrIcon,
                    heading: 'Hovr',
                    text: 'Own your web 3.0 domain, sell unique gaming NFTs items, create your digital avatar for your metaverse identity, create your own letter of wishes for loved ones and much more.',
                    new: '',
                    symbol: '',
                    appLink: 'https://hovr.site/',
                    tag: ''
                }
            ]
        },
    ],
    [
        {
            id:5,
            sectionName: 'Play-2-Earn',
            sectionCards: [
                {
                    id: 50,
                    icon: PowsomeIcon,
                    heading: 'Pawsome',
                    text: 'Challenge friends and the online community by backing your puppy to race in the Metaverse!',
                    new: '',
                    symbol: '',
                    appLink: 'https://pawsome.host/',
                    tag: ''
                },
                {
                    id: 51,
                    icon: DoodleIcon,
                    heading: 'Qie Doodle',
                    text: 'Play multiplayer doodle game on QIE Blockchain.',
                    new: 'New',
                    symbol: 'DOQ',
                    appLink: 'https://qiedoodle.com/',
                    tag: '',
                },
                {
                    id: 52,
                    icon: HovrHooligans,
                    heading: 'HOvR Hooligans',
                    text: 'The 10,000 largest cities of the world fighting for world domination. Find your allies and conquer the world.',
                    new: '',
                    symbol: '',
                    appLink: '',
                    tag: '',
                    soon: 'Coming Soon'
                },
            ]
        },
    ],
    [
        {
            id:6,
            sectionName: 'Mobile apps',
            sectionCards: [
                {
                    id: 60,
                    icon: QiStablecoinIcon,
                    heading: 'QIE Wallet',
                    text: 'Send and receive QIE, tokens and NFTs from mobile app with web3 browser and many other features.',
                    new: 'New',
                    symbol: '',
                    appLink: 'https://qiewallet.qiblockchain.online/',
                    tag: '',
                }
            ]
        },
        {
            id:7,
            sectionName: 'Tools',
            sectionCards: [
                {
                    id: 70,
                    icon: TokenCreatorIcon,
                    heading: 'QIE-20 Token Creator',
                    text: 'Mint your unique token on QIE here. Tokens are fully QIE20 compliant Non-Mintable Tokens. Use the QIE token factory to mint your very own token at the click of a button.',
                    new: '',
                    symbol: '',
                    appLink: 'https://qidex.site/#/token-creator',
                    tag: ''
                },
                {
                    id: 71,
                    icon: QiStablecoinIcon,
                    heading: 'Web3 identities',
                    text: 'Next generation of the internet with decentralised DNS(dDNS) and .QIE identities and domains.  No website renewal fees. Easy payments. Own your data.',
                    new: '',
                    symbol: '',
                    appLink: '',
                    tag: '',
                    soon: 'Coming Soon'
                }
            ]
        },
        {
            id:8,
            sectionName: 'Misc',
            sectionCards: [
                {
                    id: 80,
                    icon: DocIcon,
                    heading: 'QIE Blockchain Docs',
                    text: 'Extensive information about QIE Blockchain for developers to build in ecosystem.',
                    new: '',
                    symbol: '',
                    appLink: 'https://docs.qiblockchain.online/',
                    tag: ''
                },
                {
                    id: 81,
                    icon: TokenCreatorIcon,
                    heading: 'QIE Blockchain Explorer',
                    text: 'Track blocks and transactions. Analyze on-chain data such as tokens and smart-contracts.',
                    new: '',
                    symbol: '',
                    appLink: 'https://mainnet.qiblockchain.online/',
                    tag: ''
                }
            ]
        }
    ]
];

export default config;
